<template>
  <div class="w-full">
    <div class="w-full">
      <Card class="p-5 pt-0">
        <CardFooter reloadcard showcalendar />
      </Card>
    </div>
    <div class="w-full h-full p-3" />
    <div class="h-full" v-if="isLoading">
      <Loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <Table
        :headers="AppHeader"
        :items="items"
        aria-label="My Appraisal Table"
        class="w-full"
        :has-checkbox="false"
        :has-number="false"
        :loading="false"
        :pagination-list="paginationList"
        id="printMe"
        @page="handlePage($event)"
        @itemsPerPage="handleItemsPerPage($event)"
        page-sync
      >
        <template v-slot:item="{ item }" class="tb-items">
          <div
            v-if="item.cycle"
            class="font-semibold text-sm leading-5 tracking-widest"
          >
            <span> {{ item.data.cycle }} </span>
          </div>
          <div
            v-else-if="item.periodStart"
            class="capitalize .text-sm font-normal leading-5 tracking-wider"
          >
            <span class="text-sm font-normal">
              {{ formatDate(item.data.periodStart) }}
              -
              {{ formatDate(item.data.periodEnd) }}
            </span>
          </div>
          <div v-else-if="item.status">
            <Badge
              :label="item.data.status"
              variant="primary"
              :background-color="
                item.data.status === 'active'
                  ? `rgba(19, 181, 106, 0.15)`
                  : `rgba(135, 142, 153, 0.15)`
              "
              :color="item.data.status === 'active' ? '#13B56A' : '#333333'"
              class="capitalize"
              style="
                margin-right: 12px;
                font-size: 14px;
                padding: 12px 10px;
                height: 35px;
                border-radius: 5px;
              "
            />
          </div>
          <div
            v-else-if="item.id"
            class="cursor-pointer flex"
              @click="
                $router.push({
                  name: 'PerformanceImprovmentDetails',
                  params: { id: item.data.id },
                })
              "
          >
            <Icon icon-name="icon-eye" size="xs" class="text-blueCrayola" />
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CardFooter from "@/components/CardFooter";

export default {
  name: "TMyAppraisals",
  components: {
    Table,
    Badge,
    Card,
    CardFooter,
  },
  data() {
    return {
      AppHeader: [
        { title: "Cycle", value: "cycle" },
        { title: "Duration", value: "periodStart" },
      ],
      items: [],
      employeeAppraisals: [],
      paginationList: { page: 1, lastPage: 1, total: 1, from: 1, to: 1 },
      shortTermGoals: [],
      isLoading: true,
      dialogDel: false,
      goalId: "",
      appraisalsArr: [],
      templateArr: [],
    };
  },

  methods: {
    formatDate(value) {
      const startDate = `${value}/${new Date().getFullYear()}`;

      const [mm, dd, yyyy] = startDate.split("/");

      const ammendedDate = new Date(`${dd}-${mm}-${yyyy}`).toISOString();

      return this.$DATEFORMAT(new Date(ammendedDate), "MMMM dd, yyyy");
    },

    cycleName(templateId) {
      let name;

      this.templateArr.filter((template) => {
        if (template.id === templateId) {
          name = template.agreement_title;
        }
        return {};
      });

      return name || "";
    },

    cycleId(templateId) {
      let value;

      this.templateArr.filter((template) => {
        if (template.id === templateId) {
          value = template.appraisalCycleId;
        }
        return {};
      });

      return value || "";
    },

    getPIPSetting() {
      this.$_getPIPSetting().then((response) => {
        const { plans } = response.data;

        plans.forEach((plan) => {
            this.getTemplate(plan.templateId);
        })
      });
    },

    getTemplate(templateId) {
      this.$_getOneTemplate(templateId).then((response) => {
        const templatesArr = response.data.PerformanceTemplate;
        this.loading = false;

        const arr = [];

          arr.push({
            cycle: templatesArr.agreement_title,
            status: templatesArr.status,
            id: templatesArr.id,
          });

          arr.push(
            this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
          );

        this.items.push(arr.reduce((r, c) => Object.assign(r, c), {}));
      });
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle().then((response) => {
        const appraisal = response.data.AppraisalCycle;

        const apprArray = [];

        appraisal.map((appraisalCycles) => {
          const startDate = `${appraisalCycles.cycle_start_date}`;

          const endDate = `${appraisalCycles.cycle_end_date}`;

          apprArray.push({
            periodStart: startDate,
            periodEnd: endDate,
          });

          appraisalCycles.cycles.map((cycle) => {
            this.AppHeader.push({
              title: cycle.name,
              value: cycle.name,
            });

            apprArray.push({
              [cycle.name]: `${this.formatDate(
                cycle.appraisal_starts
              )} - ${this.formatDate(cycle.appraisal_ends)}`,
            });

            return {};
          });

          this.appraisalsArr.push(
            apprArray.reduce((r, i) => Object.assign(r, i), {})
          );

          this.AppHeader.push(
            { title: "Status", value: "status" },
            { title: "", value: "id", image: true }
          );

          return {};
        });

        this.isLoading = false;

        this.getPIPSetting();
      });
    },
  },

  mounted() {
    this.getAppraisalCycle();
  },
};
</script>

<style scoped>
.active {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.inactive {
  background: rgba(135, 142, 153, 0.08);
  color: #878e99;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
</style>

